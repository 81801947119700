import React from 'react';
import { Wordmark } from '@assets';

import styles from './Footer.module.scss';
import ThemeToggle from '@components/ThemeToggle';

import trackEvent from '@js-utils/trackEvent';

export default function Footer() {
  return (
    <footer className={styles.base} data-is-dark>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h2>
            Let’s make something together.
            <br />
            Send us an{' '}
            <em>
              <a
                href="mailto:hello@oddcommon.com"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => trackEvent('LOBQSCZN')}
              >
                email.
              </a>
            </em>
          </h2>

          <div className={styles.bottom}>
            <div className={styles.contact}>
              <Wordmark className={styles.wordmark} />
              <p>
                <a
                  href="https://goo.gl/maps/n4CJfQQgrvevjWwj8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Industry City
                  <br />
                  237 36th St.
                  <br />
                  Brooklyn, NY 11232
                </a>
              </p>
              <p>
                <a href="mailto:hello@oddcommon.com" target="_blank" rel="noopener noreferrer">
                  hello@oddcommon.com
                </a>
              </p>
            </div>
            <div className={styles.spacer} />
            <div className={styles.details}>
              <ThemeToggle />
              <p className={styles.copyright}>&copy; {new Date().getFullYear()}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
