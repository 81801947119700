import React, { useContext } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { GlobalContext } from '@context';

export default function Link(props) {
  const { actions, path } = useContext(GlobalContext);
  const target = props.to && props.to.length === 1 ? props.to : `${props.to}/`;
  return (
    <GatsbyLink
      {...props}
      to={`${target}`}
      onClick={() => {
        if (path !== props.to) actions.setTransitionState('start');
      }}
    >
      {props.children}
    </GatsbyLink>
  );
}
