import React, { useContext, useEffect, useRef } from 'react';
import { GlobalContext } from '@context';
import { css } from '@oddcommon/utils';

import styles from './index.module.scss';

export default function Transition() {
  const { actions, transitionState } = useContext(GlobalContext);
  const transitionRef = useRef();

  const handleTransition = () => {
    if (!transitionState) return false;
    actions.step(transitionState);
  };

  // Add a listener for transition end to happen
  // This could be anything really as long as it calls
  // step after each cycle is complete
  useEffect(() => {
    if (!transitionState) return false;
    transitionRef.current.addEventListener('transitionend', handleTransition);
    return () => {
      if (transitionRef.current) {
        transitionRef.current.removeEventListener('transitionend', handleTransition);
      }
    };
  }, [transitionState]);

  return (
    <div ref={transitionRef} className={css(styles.transition, styles[transitionState])}></div>
  );
}
