const trackEvent = id => {
  if (typeof window !== 'undefined') {
    if (window.fathom) {
      window.fathom.trackGoal(id, 0);
    } else {
      console.error(`Fathom is missing! Event ${id} could not be logged.`);
    }
  }
};

export default trackEvent;
