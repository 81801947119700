import React, { useState, createContext, useEffect, useContext } from 'react';

export const GlobalContext = createContext();

export const useGlobal = () => useContext(GlobalContext);
import * as Fathom from 'fathom-client';

const TRANSITION_STATES = {
  START: 'start',
  IN: 'in',
  READY: 'ready',
  OUT: 'out',
  COMPLETE: 'complete',
};

export default function ContextProvider({ children, path }) {
  const [hover, setHover] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [transitionState, setTransitionState] = useState(null);
  const [resolvedChildren, setResolvedChildren] = useState(children);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [theme, setTheme] = useState(null);
  const [lowBattery, setLowBattery] = useState(false);
  const [firstTimer, setFirstTimer] = useState(false);

  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
  }, [theme]);

  // Will step through the states
  const step = currentState => {
    let keys = Object.keys(TRANSITION_STATES);
    let nextIndex = keys.indexOf(currentState.toUpperCase()) + 1;
    let nextItem = keys[nextIndex];

    setTransitionState(TRANSITION_STATES[nextItem]);
  };

  // When a page is ready that page should notify and continue transition
  useEffect(() => {
    if (!mounted && pageLoaded) setMounted(true);
    if (mounted && pageLoaded && transitionState === TRANSITION_STATES.IN) {
      setTransitionState(TRANSITION_STATES.READY);
    }
  }, [pageLoaded, transitionState]);

  // Any specific actions that should happen while transitioning
  useEffect(() => {
    switch (transitionState) {
      case TRANSITION_STATES.START:
        setPageLoaded(false);
        break;
      case TRANSITION_STATES.IN:
        setResolvedChildren(children);
        window.scrollTo(0, 0);
        break;
      case TRANSITION_STATES.READY:
        window.CURRENT_SCROLL = 0;
        break;
      case TRANSITION_STATES.OUT:
        setTransitionState(TRANSITION_STATES.COMPLETE);
        break;
      case TRANSITION_STATES.COMPLETE:
        setTransitionState(null);
        break;
    }
  }, [transitionState]);

  // Initial mount & catch for path changes
  useEffect(() => {
    if (!mounted) return false;
    if (transitionState === TRANSITION_STATES.IN) {
      setResolvedChildren(children);
      window.scrollTo(0, 0);
      setTransitionState(TRANSITION_STATES.READY);
    }
    if (!transitionState) {
      setTransitionState(TRANSITION_STATES.START);
    }
    Fathom.trackPageview();
  }, [path]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.CURRENT_SCROLL = window.scrollY;
    });
    Fathom.load('GZLBJTSH', {
      includedDomains: ['www.oddcommon.com', 'oddcommon.com'],
    });
    Fathom.trackPageview();
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        actions: {
          setHover,
          setTransitionState,
          step,
          setPageLoaded,
          setTheme,
          setLowBattery,
          setFirstTimer,
        },
        firstTimer,
        hover,
        transitionState,
        mounted,
        path,
        theme,
        lowBattery,
      }}
    >
      {resolvedChildren}
    </GlobalContext.Provider>
  );
}
