import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import ContextProvider from '@context';
import Nav from '@components/Nav';
import Footer from '@components/Footer';
import Transition from '@components/Transition';

import './styles/index.scss';
import styles from './BaseLayout.module.scss';

export default function Layout({ children, path }) {
  return (
    <>
      <ContextProvider path={path}>
        <Nav />
        <div className={styles.base}>{children}</div>
        <Footer />
        <Transition />
      </ContextProvider>
    </>
  );
}
