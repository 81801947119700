import React, { useEffect, useState } from 'react';
import { useGlobal } from '@context';

import styles from './ThemeToggle.module.scss';
import { css } from '@oddcommon/utils';

const MONTHLY_THEMES = [
  'frost',
  'frost',
  '',
  '',
  '',
  'pride',
  '',
  '',
  '',
  'spooky',
  'harvest',
  'frost',
];
const DAILY_THEMES = [
  { month: 11, day: 5, id: 'birthday' },
  { month: 4, day: 22, id: 'earth' },
  { month: 7, day: 4, id: '4th'}
];

const HOLIDAY_THEMES = [
  {
    name: "Valentine's Day",
    id: 'valentines',
  },
  {
    name: 'Spring Festival',
    id: 'lunar-new-year',
  },
];

const ThemeToggle = props => {
  const { className } = props;
  const { theme, actions } = useGlobal();
  const { setTheme } = actions;
  const [holidays, setHolidays] = useState([]);

  const currentDate = new Date();

  useEffect(() => {
    const currentDate = encodeURIComponent(new Date().toLocaleDateString());

    fetch(`${window.location.origin}/holidays/${currentDate}`)
      .then(async response => {
        if (response.ok) {
          let { holidays } = await response.json();
          if (holidays) {
            setHolidays(holidays);
          }
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const month = currentDate.getMonth();
  const day = currentDate.getDate();

  // Set today's theme to the monthly theme by default
  let todaysTheme = MONTHLY_THEMES[month];

  // Override the monthly theme if there is a holiday theme
  HOLIDAY_THEMES.forEach(theme => {
    holidays.forEach(holiday => {
      if (theme.name === holiday.name) {
        todaysTheme = theme.id;
      }
    });
  });

  // Override the monthly/holiday theme if there is a daily theme
  DAILY_THEMES.forEach(theme => {
    if (theme.month === month + 1 && theme.day === day) {
      todaysTheme = theme.id;
    }
  });

  // const noTheme = todaysTheme === '';

  return (
    <label className={css(styles.wrapper, className)}>
      <input
        type="checkbox"
        aria-label="Theme Toggle"
        title="Theme Toggle"
        className={styles.input}
        defaultChecked={!!theme}
        onChange={e => {
          const newTheme = e.target.checked ? todaysTheme : null;
          setTheme(newTheme);
        }}
      />
      <div className={styles.toggle}>
        <div className={styles.indicator}>
          <span className={styles.icon}></span>
        </div>
      </div>
    </label>
  );
};

export default ThemeToggle;
