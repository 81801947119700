// During route transitions, make sure that the page doesn't jump to the top before we fade out
const { globalHistory } = require('@reach/router');

exports.onRouteUpdate = () => {
  window.scrollTo(0, window.CURRENT_SCROLL);
};

exports.onInitialClientRender = () => {
  globalHistory._onTransitionComplete();
};
