import React, { useState, useRef, useEffect, useContext } from 'react';
import Link from '@components/Link';
import { Logo } from '@assets';

import styles from './Nav.module.scss';
import { GlobalContext } from '@context';
import { css } from '@oddcommon/utils';

// Format colors into {r,g,b}
const formatColor = rawColor => {
  let color = rawColor.trim();
  let r, g, b;
  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = parseInt(color[1]);
    g = parseInt(color[2]);
    b = parseInt(color[3]);
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = '0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&');
    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }
  return { r, g, b };
};

// Check if two {r,g,b} match
const checkColorMatch = (colorA, colorB) => {
  if (parseInt(colorA.r) !== parseInt(colorB.r)) {
    return false;
  }
  if (parseInt(colorA.g) !== parseInt(colorB.g)) {
    return false;
  }
  if (parseInt(colorA.b) !== parseInt(colorB.b)) {
    return false;
  }
  return true;
};

// Determine if a color with the format {r,g,b} is light or dark
const lightOrDark = ({ r, g, b }) => {
  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  let hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'light';
  } else {
    return 'dark';
  }
};

function hasNoAncestor(node, testAncestor) {
  if (!node) return true;
  if (node === testAncestor) return false;
  return hasNoAncestor(node.parentNode, testAncestor);
}

const NAV_HIDE_POINT = 20;
let invertDebounce;

export default function Nav() {
  const { hover } = useContext(GlobalContext);

  // Set up light/dark switching
  const [isDark, setIsDark] = useState(false);
  const baseRef = useRef(null);
  useEffect(() => {
    const update = () => {
      if (!baseRef.current) return;
      const measurePoint = [0, 48];

      // Find the first first at the point in the center of the nav that isn't a part of the nav
      const elementBehind = document
        .elementsFromPoint(...measurePoint)
        .find(el => hasNoAncestor(el, baseRef.current));

      // Causes a slight jitter in render at times so debounce to smooth things out
      clearTimeout(invertDebounce);
      invertDebounce = setTimeout(() => {
        let _base;
        // Read the Background color of the element behind
        const elementColor = formatColor(getComputedStyle(elementBehind).backgroundColor);
        // Get the color background css variable value
        const colorBackground = formatColor(
          getComputedStyle(document.body).getPropertyValue('--color-background')
        );
        // Get the color foreground css variable value
        const colorForeground = formatColor(
          getComputedStyle(document.body).getPropertyValue('--color-foreground')
        );

        // Set the base to light or dark if it's an exact match to the variable,
        // otherwise calculate it for real
        if (checkColorMatch(colorBackground, elementColor)) {
          _base = 'light';
        } else if (checkColorMatch(colorForeground, elementColor)) {
          _base = 'dark';
        } else {
          _base = lightOrDark(elementColor);
        }

        setIsDark(_base === 'light' ? false : true);
      }, 0);
      requestAnimationFrame(update);
    };
    update();
  }, []);

  // Set up hiding on scroll
  const [hidden, setHidden] = useState(false);
  useEffect(() => {
    let prevScrollY = window.scrollY;
    const showHideNav = () => {
      if (window.scrollY > prevScrollY && window.scrollY > NAV_HIDE_POINT) {
        setHidden(true);
      } else {
        setHidden(false);
      }
      prevScrollY = window.scrollY;
    };
    window.addEventListener('scroll', showHideNav, { passive: true });
    return () => {
      window.removeEventListener('scroll', showHideNav);
    };
  }, []);

  return (
    <nav
      className={css(styles.base, isDark && styles.inverted, hover && styles.hover)}
      ref={baseRef}
    >
      <Link to="/" className={styles.logo}>
        <span className="visuallyHidden">Go to home</span>
        <Logo />
      </Link>
      <div className={styles.linksWrapper}>
        <ul className={css(styles.links, hidden && styles.hidden)}>
          <li>
            <Link to="/careers">Careers</Link>
          </li>
          <li>
            <a href="mailto:hello@oddcommon.com">Contact</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
